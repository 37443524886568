<script setup lang="ts">
import type { HomeBanners } from "@/types";

const { t } = useT();
const router = useRouter();

type TournamentBannerType = HomeBanners[number];

defineProps<{ banner: TournamentBannerType }>();

const { open } = useTaoModals();

const { entriesSum, coinsSum, activeStatus, subscribedStatus, loadingSubscribtions, handleTournament } =
	useTournamentData({ open });

const textBtn = computed(() => {
	if (!activeStatus.value) {
		return t("About tournaments");
	}
	return subscribedStatus.value ? t("Play now") : t("Join now");
});

const handleClick = () => {
	if (!activeStatus.value) {
		router.push("/tournaments/");
		return;
	}
	handleTournament();
};
</script>

<template>
	<MBanner
		class="tournament-banner"
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
	>
		<template #default>
			<AText class="banner-title" :size="20" :modifiers="['uppercase', 'medium', 'italic']" as="div">
				{{ t("Turbo thrill tournament") }}
			</AText>
			<AText class="banner-subtitle" type="h8" as="div">
				{{ t("Prize Pool") }}
			</AText>
		</template>
		<template #description>
			<div v-if="banner" class="app-banner__info">
				<div v-if="coinsSum" class="app-banner__desc-money app-banner__desc-money--coins">
					<NuxtImg
						class="app-banner__desc-ico app-banner__desc-ico--coins"
						src="/nuxt-img/banners/coins.png"
						format="webp"
					/>
					<div class="app-banner__sum app-banner__sum--coins">
						<AText type="h3 h2-md" :modifiers="['bold']">{{ numberFormat(coinsSum) }}</AText>
					</div>
				</div>
				<div v-if="entriesSum" class="app-banner__desc-money app-banner__desc-money--entries">
					<NuxtImg
						class="app-banner__desc-ico app-banner__desc-ico--entries"
						src="/nuxt-img/banners/entries.png"
						format="webp"
					/>
					<div class="app-banner__sum app-banner__sum--entries">
						<AText type="h3 h2-md">{{ numberFormat(entriesSum) }} </AText>
					</div>
				</div>
			</div>
		</template>
		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn" @click="handleClick">
				<AAnimationSpin v-if="loadingSubscribtions">
					<NuxtIcon name="24/loader" filled />
				</AAnimationSpin>
				<template v-else>{{ textBtn }}</template>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.tournament-banner {
	.banner-title {
		margin-bottom: 4px;
	}

	@include media-breakpoint-down(sm) {
		&:deep(.app-banner__content) {
			padding-bottom: 24px;
			justify-content: flex-end;
		}

		&:deep(.app-banner__actions) {
			margin-top: 8px;
		}
		&:deep(.app-banner__desc) {
			margin: 4px 0 0;
		}
	}
}
</style>
